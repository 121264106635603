<template>
  <el-dialog
    class="edit-dialog"
    :visible="visible"
    :title="title || $t('title')"
    @close="$emit('close')"
    @closed="$emit('closed')"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
      label-width="100px"
      :show-message="true"
      size="small"
    >
      <el-form-item v-for="item in data" :key="item.name" :prop="item.name" :label="item.label">
        <el-input v-if="!item.custom" v-model="formData[item.name]" :type="item.type" />
        <slot :name="item.name" :formdata="formData" />
      </el-form-item>
    </el-form>

    <dialog-footer
      slot="footer"
      :loading="submitLoading"
      :disabled="submitDisabled"
      :show-delete="showDelete"
      :submit-label="submitLabel"
      @cancel="handleClose"
      @submit="handleSubmit"
      @delete="$emit('delete')"
    />
  </el-dialog>
</template>

<script>
import DialogFooter from './DialogFooter'

export default {
  name: 'EditDialog',

  components: {
    DialogFooter,
  },

  props: {
    title: String,
    visible: Boolean,
    showDelete: Boolean,
    error: Boolean,
    submitLabel: String,
    data: {
      type: Array,
      required: true,
    },
    allowSameData: Boolean,
  },

  data() {
    return {
      formData: {},
      formRules: {},

      submitLoading: false,
      submitDisabled: false,
    }
  },

  watch: {
    data: {
      handler() {
        this.handleReset()
      },
      deep: true,
      immediate: true,
    },
    formData: {
      handler(val) {
        this.$emit('clearError')
        if (this.allowSameData || this.data.some(item => item.value !== val[item.name])) {
          this.submitDisabled = false
        } else {
          this.submitDisabled = true
        }
      },
      deep: true,
      immediate: true,
    },
    error(val) {
      this.submitLoading = !val
      this.submitDisabled = val
    },
  },

  methods: {
    handleReset() {
      this.formData = {}
      this.formRules = {}
      this.$refs.form && this.$refs.form.clearValidate()
      this.submitLoading = false
      this.submitDisabled = false
      this.data.forEach(item => {
        this.$set(this.formData, item.name, item.value || '')
        if (item.message) {
          this.$set(this.formRules, item.name, {
            required: true,
            message: item.message,
            trigger: 'blur',
          })
        }
      })
    },
    handleClose() {
      this.$emit('close')
      this.handleReset()
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.submitLoading = true
          this.$emit('submit', this.formData)
        } else {
          this.submitDisabled = true
          return false
        }
      })
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": "Data edit"
  },
  "ru": {
    "title": "Изменение данных"
  }
}
</i18n>

<style lang="scss"></style>
