<template>
  <el-popover
    v-model="visible"
    placement="bottom-end"
    popper-class="info-select__popover"
    :visible-arrow="false"
  >
    <el-button
      slot="reference"
      size="small"
      :type="isAction ? 'primary' : 'default'"
      :class="[
        'info-select__button',
        'el-button_icon--prefix',
        { 'info-select__button--placeholder': !label },
      ]"
    >
      <icon v-if="isAction" height="16" width="16" name="add-circle" />
      {{ label || placeholder }}
      <i v-if="!isAction" class="el-select__caret el-icon-arrow-down" />
    </el-button>
    <div class="info-select">
      <div class="info-select__container">
        <el-input v-model="searchQuery" size="small" class="info-select__search">
          <icon slot="prefix" height="16" width="16" name="search" />
        </el-input>

        <div v-if="showGroups">
          <div v-for="group in filteredOptions" :key="group.label" class="info-select__group">
            <span class="info-select__group__title">{{ group.label }}</span>
            <ul class="info-select__options">
              <li
                v-for="option in group.options"
                :key="option.name"
                :class="[
                  'info-select__option',
                  {
                    'info-select__option--active': activeOption.name === option.name,
                  },
                ]"
                @mouseenter="handleMouseEnter(option, group)"
                @click="handleSelect(option)"
              >
                <icon v-if="option.icon" :name="option.icon" />
                {{ option.label }}
              </li>
            </ul>
          </div>
        </div>
        <ul v-else class="info-select__options">
          <li
            v-for="option in filteredOptions"
            :key="option.name"
            :class="[
              'info-select__option',
              {
                'info-select__option--active': activeOption.name === option.name,
              },
            ]"
            @mouseenter="handleMouseEnter(option)"
            @click="handleSelect(option)"
          >
            <icon v-if="option.icon" width="24" height="24" :name="option.icon" />
            {{ option.label }}
          </li>
        </ul>
      </div>
      <div class="info-select__info">
        <span v-if="activeOption.label" class="info-select__info__title">
          {{ activeOption.label }}
        </span>
        <p
          v-if="activeOption.description"
          class="info-select__info__description"
          v-html="activeOption.description"
        />
        <p
          v-if="activeOption.example"
          class="info-select__info__example"
          v-html="activeOption.example"
        />
        <a
          v-if="activeOption.link"
          ref="noreferrer noopener"
          :href="activeOption.link.href"
          arget="_blank"
          class="info-select__info__link"
        >
          {{ activeOption.link.label }}
        </a>
      </div>
    </div>
  </el-popover>
</template>

<script>
export default {
  name: 'InfoSelect',

  props: {
    label: String,
    value: String,
    showGroups: Boolean,
    options: {
      type: Array,
      required: true,
    },
    isAction: Boolean,
    placeholder: String,
  },

  data() {
    return {
      visible: false,
      searchQuery: '',
      activeOption: {},
      activeGroup: {},
    }
  },

  computed: {
    filteredOptions() {
      if (this.showGroups) {
        return this.options
          .map(group => {
            group.options = group.options.filter(
              option =>
                !option.disabled &&
                (!this.searchQuery ||
                  option
                    .toString()
                    .toLowerCase()
                    .includes(this.searchQuery.toLowerCase()))
            )
            return group
          })
          .filter(group => group.options.length)
      } else {
        return this.options.filter(
          option =>
            !option.disabled &&
            (!this.searchQuery ||
              option
                .toString()
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()))
        )
      }
    },
  },

  watch: {
    value: {
      handler(val) {
        if (this.showGroups) {
          this.options.some(group => {
            const option = group.options.find(option => option.name === val)
            if (option) {
              this.activeOption = option
              this.activeGroup = group
              return true
            }
          })
        } else {
          const option = this.options.find(option => option.name === val)
          if (option) {
            this.activeOption = option
          }
        }
      },
      immediate: true,
    },
    options: {
      handler() {
        // todo: check options length (maybe all disabled)
        if (!this.value) {
          if (this.showGroups) {
            this.activeOption = this.filteredOptions[0].options[0]
            this.activeGroup = this.filteredOptions[0]
          } else {
            this.activeOption = this.filteredOptions[0]
          }
        }
      },
      immediate: true,
      deep: true,
    },
    searchQuery() {
      if (this.showGroups) {
        if (
          !this.options.some(group => group.some(option => option.name === this.activeOption.name))
        ) {
          this.activeGroup = this.options[0]
          this.activeOption = this.options[0].options[0]
        }
      } else {
        if (!this.options.some(option => option.name === this.activeOption.name)) {
          this.activeOption = this.options[0]
        }
      }
    },
  },

  methods: {
    handleSelect(option) {
      this.$emit('input', option.name)
      this.$emit('change', option, this.activeGroup)
      this.visible = false
    },
    handleMouseEnter(option, group) {
      this.activeOption = option
      this.activeGroup = group
    },
  },
}
</script>

<style lang="scss">
.info-select__popover.el-popover {
  padding: 0;
  border: 0;
}
.info-select__button.el-button.el-button--default {
  color: $color-layout-dark-01;
  font-weight: 400;
  text-align: left;
  &:hover {
    background: transparent;
    border: 1px solid #ced2ea;
  }
  &:focus {
    background: transparent;
    border: 1px solid $color-primary;
  }
}
.info-select__button--placeholder.el-button.el-button--default {
  color: #ced2ea;
}
.info-select {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  max-width: 465px;
  min-height: 300px;
  text-align: left;
  & > * {
    padding: $spacing-m;
  }
}
.info-select__search {
  margin-bottom: $spacing-s;
}
.info-select__info {
  width: 200px;
  border-radius: 0 4px 4px 0;
  border-right: 1px solid $color-layout-light-03;
  background-color: $color-layout-light-02;
}
.info-select__group {
  margin-bottom: $spacing-xs;
}
.info-select__group__title {
  display: block;
  font-weight: 500;
  margin-bottom: $spacing-2xs;
}
.info-select__info__title {
  display: block;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: $spacing-xs;
}
.info-select__info__description,
.info-select__info__example {
  margin-bottom: $spacing-xs;
}
.info-select__info__example {
  font-style: italic;
  opacity: 0.8;
}
.info-select__info__link {
  position: absolute;
  bottom: $spacing-m;
  left: calc(50% + #{$spacing-m});
}
.info-select__option {
  list-style: none;
  padding: $spacing-xs;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 0.8;

  &:hover {
    background-color: $color-layout-light-03;
    transition: all 0.3s ease-in-out;
  }
}
.info-select__option--active {
  background-color: $color-layout-light-03;
  transition: all 0.3s ease-in-out;
}
.info-select__option--disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background: transparent;
  &:hover {
    background: transparent;
  }
}
</style>
