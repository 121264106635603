<template>
  <div class="dialog-footer">
    <el-button
      v-if="showDelete"
      type="danger"
      class="dialog-footer__btn-destructive el-button_icon--prefix"
      :disabled="loading"
      size="small"
      @click="$emit('delete')"
    >
      <icon height="16" width="16" name="trash" size="small" />{{ $t('actions.delete') }}
    </el-button>
    <el-button v-if="!noCancel" :disabled="loading" size="small" @click="$emit('cancel')">
      {{ cancelLabel || $t('actions.cancel') }}
    </el-button>
    <el-button
      :disabled="disabled"
      :loading="loading"
      size="small"
      type="primary"
      @click="$emit('submit')"
    >
      {{ submitLabel || $t('actions.save') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'DialogFooter',

  props: {
    noCancel: Boolean,
    cancelLabel: String,
    submitLabel: String,
    showDelete: Boolean,
    loading: Boolean,
    disabled: Boolean,
  },
}
</script>

<style lang="scss">
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: $color-layout-light-01;
  & > * {
    min-width: 90px;
  }
}
.dialog-footer__btn-destructive {
  margin-right: auto;
}
</style>
