var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-popover',{attrs:{"placement":"bottom-end","popper-class":"info-select__popover","visible-arrow":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('el-button',{class:[
      'info-select__button',
      'el-button_icon--prefix',
      { 'info-select__button--placeholder': !_vm.label },
    ],attrs:{"slot":"reference","size":"small","type":_vm.isAction ? 'primary' : 'default'},slot:"reference"},[(_vm.isAction)?_c('icon',{attrs:{"height":"16","width":"16","name":"add-circle"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.label || _vm.placeholder)+" "),(!_vm.isAction)?_c('i',{staticClass:"el-select__caret el-icon-arrow-down"}):_vm._e()],1),_c('div',{staticClass:"info-select"},[_c('div',{staticClass:"info-select__container"},[_c('el-input',{staticClass:"info-select__search",attrs:{"size":"small"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}},[_c('icon',{attrs:{"slot":"prefix","height":"16","width":"16","name":"search"},slot:"prefix"})],1),(_vm.showGroups)?_c('div',_vm._l((_vm.filteredOptions),function(group){return _c('div',{key:group.label,staticClass:"info-select__group"},[_c('span',{staticClass:"info-select__group__title"},[_vm._v(_vm._s(group.label))]),_c('ul',{staticClass:"info-select__options"},_vm._l((group.options),function(option){return _c('li',{key:option.name,class:[
                'info-select__option',
                {
                  'info-select__option--active': _vm.activeOption.name === option.name,
                },
              ],on:{"mouseenter":function($event){return _vm.handleMouseEnter(option, group)},"click":function($event){return _vm.handleSelect(option)}}},[(option.icon)?_c('icon',{attrs:{"name":option.icon}}):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")],1)}),0)])}),0):_c('ul',{staticClass:"info-select__options"},_vm._l((_vm.filteredOptions),function(option){return _c('li',{key:option.name,class:[
            'info-select__option',
            {
              'info-select__option--active': _vm.activeOption.name === option.name,
            },
          ],on:{"mouseenter":function($event){return _vm.handleMouseEnter(option)},"click":function($event){return _vm.handleSelect(option)}}},[(option.icon)?_c('icon',{attrs:{"width":"24","height":"24","name":option.icon}}):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")],1)}),0)],1),_c('div',{staticClass:"info-select__info"},[(_vm.activeOption.label)?_c('span',{staticClass:"info-select__info__title"},[_vm._v(" "+_vm._s(_vm.activeOption.label)+" ")]):_vm._e(),(_vm.activeOption.description)?_c('p',{staticClass:"info-select__info__description",domProps:{"innerHTML":_vm._s(_vm.activeOption.description)}}):_vm._e(),(_vm.activeOption.example)?_c('p',{staticClass:"info-select__info__example",domProps:{"innerHTML":_vm._s(_vm.activeOption.example)}}):_vm._e(),(_vm.activeOption.link)?_c('a',{ref:"noreferrer noopener",staticClass:"info-select__info__link",attrs:{"href":_vm.activeOption.link.href,"arget":"_blank"}},[_vm._v(" "+_vm._s(_vm.activeOption.link.label)+" ")]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }